import React, { useRef, useState } from 'react'
import './Modal.scss'
import DoubleInputGroup from '../form/DoubleInputGroup';
import axios from 'axios';
import { toast } from 'react-toastify';
import { BeatLoader } from 'react-spinners';
import { useFileUpload } from '../form/hooks/useFileUpload';
import UploadInput from '../form/UploadInput';
import SectionDescription from '../form/SectionDescription';
import InputField from '../form/InputField';
function RiderRegisterModal({riderFormData,setRiderFormData,setIsRiderModal}) {
    const policeReportRef = useRef();
    const addressRef = useRef();

    const [isLoading,setIsLoading] = useState(false)
    const handleInputChange = (e) => {
        const { name, value, type, checked } = e.target;
        setRiderFormData({
          ...riderFormData,
          [name]: type === 'checkbox' ? checked : value,
        });
    };

    const {
        file: policyReportFile,
        fileUrl: policyReportFileUrl,
        uploadProgress: policyReportPerc,
        isFileUploading: ispolicyReportChange,
        uploadError: policyReportError,
        handleFileUpload: handlePolicyReportUpload
    } = useFileUpload();
    const {
        file: addressFile,
        fileUrl: addressFileUrl,
        uploadProgress: addressPerc,
        isFileUploading: isAddressChange,
        uploadError: addressError,
        handleFileUpload: handleAddressUpload
    } = useFileUpload();
    
    const handleSubmit = async (e)=>{
        e.preventDefault();
        setIsLoading(true)
        try{

            
            const data = {
                fullname: riderFormData.fullname,
                email: riderFormData.email,
                phoneNumber: riderFormData.phoneNumber,
                password: riderFormData.password,
                confirmPassword:riderFormData.confirmPassword,
                country:riderFormData.country,
                address:riderFormData.address,
                guarantor_name: riderFormData.guarantor_name,
                guarantor_phone:riderFormData.guarantor_phone,
                guarantor_occupation:riderFormData.guarantor_occupation,
                guarantor_relationship:riderFormData.guarantor_relationship,
                isAgreement: riderFormData.isAgreement,
                address_proof:addressFileUrl,
                police_report:policyReportFileUrl
            }
            console.log("Data: ",data)
            const url = "https://airander.onrender.com/rider/register"
            //  const url = "http://localhost:6001/rider/register"
            const res = await axios.post(url,{...data})
            setIsLoading(false)
            console.log("result:",res.data)
            setRiderFormData({
                fullname:"",
                email: "",
                phoneNumber: "",
                password: "",
                confirmPassword:"",
                country:"",
                address:"",
                guarantor_name:"",
                guarantor_phone:"",
                guarantor_occupation:"",
                guarantor_relationship:"",
                isAgreement:"",
                address_proof:"",
                police_report:""
            })
            setIsRiderModal(false)
            toast.success("successfully registered, check your email")

        }catch(err){
            setIsLoading(false)
            const errMessage = err?.response?.data?.error || "an Unexpected error has occured: please try again"
            toast.error(errMessage)
            console.log(err)
        }
    }
  return (
    <div className='balance_modal'  onClick={setIsRiderModal.bind(this, false)} >
        <div onClick={(e) => e.stopPropagation()} className='balance_modal_content'>
          <div className='balance_modal_wrapper'>
              <img 
              onClick={setIsRiderModal.bind(this, false)}
              className='otc_back' src="/images/backIcon.svg" alt='close' 
              />
          
            <div className="modal-title">
                <div className='step_title'>
                    <div >
                        <p className="enter-amount">Become A Rider</p>
                        <p className="enter-an-amount">Complete the form below to register as a Rider</p>
                    </div>
                    <img src='/images/otc/step_one.svg' alt='' />
                </div>
            </div>

            <div className='otcform_container' style={{paddingTop:"10px"}}>
            <form onSubmit={handleSubmit} >
                        {/* <div>
                         <p className='input_text'>Bank Name</p>
                         <input className='otc_select_full' style={{width:"100%"}}  name="bank_name" onChange={handleInputChange} />
                        </div> */}
                        <div style={{display:"flex",flexDirection:"column"}}>

                            <SectionDescription name="Rider Credentials"/>
                            <DoubleInputGroup label1="Fullname" label2="Email" handleChange={handleInputChange} name1="fullname" name2="email" value1={riderFormData.fullname} value2={riderFormData.email} />
                            {/* <DoubleInputGroup label1="Phone Number" label2="bvn" handleChange={handleInputChange} name1="phoneNumber"  value1={riderFormData.phoneNumber} /> */}
                            <InputField label="Phone Number" name="phoneNumber" handleChange={handleInputChange}/>
                            <DoubleInputGroup type1='password' type2="password" label1="Password" label2="Confirm Password" handleChange={handleInputChange} name1="password" name2="confirmPassword"  value1={riderFormData.password} value2={riderFormData.confirmPassword}/>
                            <DoubleInputGroup select label1="Country Of Residence" label2="Address" handleChange={handleInputChange} name1="country" name2="address">
                                <option value="USA">United States</option>
                                <option value="United Kingdom">United Kingdom</option>
                                <option value="China">China</option>
                                {/* {
                                    countries.map((country)=>(
                                        <option value={country.name}>{country.name}</option>
                                    ))
                                } */}

                                <option value="nigeria">Nigeria</option>
                               
                            </DoubleInputGroup>
                            <SectionDescription name="Guarantor"/>
                            <DoubleInputGroup label1="Name" label2="Phone Number" handleChange={handleInputChange} name1="guarantor_name" name2="guarantor_phone"  value1={riderFormData.guarantor_name} value2={riderFormData.guarantor_phone} />
                            <DoubleInputGroup label1="Occupation" label2="Relationship" handleChange={handleInputChange} name1="guarantor_occupation" name2="guarantor_relationship"  value1={riderFormData.guarantor_occupation} value2={riderFormData.guarantor_relationship} />
                            <SectionDescription name="Document Uploads"/>
                            <UploadInput 
                            label="police Report" 
                            refs={policeReportRef}
                            handleChange={handlePolicyReportUpload}
                            file={policyReportFile}
                            error={policyReportError}
                            perc={policyReportPerc}
                            progressChange={ispolicyReportChange}
                            />

                            <UploadInput 
                            label="Proof of Address" 
                            label1="(Utility bill, BankStatment)"
                            refs={addressRef}
                            handleChange={handleAddressUpload}
                            file={addressFile}
                            error={addressError}
                            perc={addressPerc}
                            progressChange={isAddressChange}
                            />
                        </div>
                         <div style={{display:"flex",alignItems:"center", gap:"10px",marginTop:"15px"}}>

                         <input
                            type="checkbox"
                            className="form-check-input"
                            id="isAgreement"
                            name="isAgreement"
                            checked={riderFormData.isAgreement}
                            onChange={handleInputChange}
                        />
                        <p className='input_text' style={{margin:"0px",padding:"0px",color:"#800080"}}> Agree to our Terms of Service and Privacy policy</p>

                         
                        </div>


                        <div className="modal-btn" style={{display:"flex", gap:"12px", marginTop:"32px"}}>
                            <button onClick={setIsRiderModal.bind(this,false)} className="btn otc_btn_cancel">
                                Cancel
                            </button>
                        
                            <button  className="btn" disabled={isLoading}>   
                                {
                                    isLoading ?
                                    <BeatLoader /> :
                                    <>
                                    Sign Up
                                    </>
                                }
                            </button>

                        </div>

            </form>

                
            </div>

            
          </div>
        </div>
    </div>
  )
}

export default RiderRegisterModal