import { useState } from 'react';
import axios from 'axios';

export const useFileUpload = () => {
    const [file, setFile] = useState(null);
    const [fileUrl, setFileUrl] = useState(null);
    const [uploadProgress, setUploadProgress] = useState(0);
    const [isFileUploading, setIsFileUploading] = useState(false);
    const [uploadError, setUploadError] = useState(false);

    const handleFileUpload = async (files) => {
        if (!files || files.length === 0) return;

        setUploadError(false);
        setUploadProgress(0);
        setIsFileUploading(true);
        const file = files[0];
        const form = new FormData();
        form.append("image", file);

        // console.log("file",file)

        try {
            const url = 'https://airander.onrender.com/upload';
            // const url = "http://localhost:6001/upload"
           
            let res = await axios.post(url, form, {
                onUploadProgress: progressEvent => {
                    setUploadProgress(
                        parseInt(
                            Math.round((progressEvent.loaded * 100) / progressEvent.total)
                        )
                    );
                }
            });

            console.log("uploaded url: ",res?.data)

            setFileUrl(res?.data?.data.url);
            setTimeout(() => {
                setUploadProgress(0);
                setIsFileUploading(false);
            }, 10000);
            setFile(file);
        } catch (err) {
            console.log("err:",err)
            setIsFileUploading(false);
            setUploadProgress(0);
            setUploadError(true);
            setTimeout(() => {
                setUploadError(false);
            }, 10000);
        }
    };

    return {
        file,
        fileUrl,
        uploadProgress,
        isFileUploading,
        uploadError,
        handleFileUpload
    };
};
