import React, { useEffect } from 'react';
import AOS from 'aos';
import GLightbox from 'glightbox';
import 'aos/dist/aos.css';
import 'glightbox/dist/css/glightbox.css';
import './gallery.css';


const galleryItems = [
    { src: 'images/gallery/gallery-1.jpg', delay: 100 },
    { src: 'images/gallery/gallery-2.jpg', delay: 150 },
    { src: 'images/gallery/gallery-3.jpg', delay: 200 },
    { src: 'images/gallery/gallery-4.jpg', delay: 250 },
    { src: 'images/gallery/gallery-5.jpg', delay: 300 },
    { src: 'images/gallery/gallery-6.jpg', delay: 350 },
    { src: 'images/gallery/gallery-7.jpg', delay: 400 },
    { src: 'images/img/gallery/gallery-8.jpg', delay: 450 },
  ];

function Gallery() {
    useEffect(() => {
        AOS.init({
          duration: 1000,
          easing: 'ease-in-out',
          once: true,
          mirror: false,
        });
    
        const lightbox = GLightbox({
          selector: '.gallery-lightbox',
        });
    
        return () => {
          lightbox.destroy();
        };
     }, []);
  return (
    <div className="container">
        <div className="section-title" data-aos="fade-up">
          <h2>Gallery</h2>
          <p>Check our Gallery</p>
        </div>
        <div className="row g-0" data-aos="fade-left">
          {galleryItems.map((item, index) => (
            <div className="col-lg-3 col-md-4" key={index}>
              <div className="gallery-item" data-aos="zoom-in" data-aos-delay={item.delay}>
                <a href={item.src} className="gallery-lightbox">
                  <img src={item.src} alt="" className="img-fluid" />
                </a>
              </div>
            </div>
          ))}
        </div>
      </div>
  )
}

export default Gallery