import React, { useEffect } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';
import  PureCounter  from '@srexi/purecounterjs';


import './counter.css';


const countsData = [
    { icon: "bi bi-emoji-smile", end: 232, text: "Happy Clients", delay: 0 },
    { icon: "bi bi-journal-richtext", end: 521, text: "Projects", delay: 100 },
    { icon: "bi bi-headset", end: 1463, text: "Hours Of Support", delay: 200 },
    { icon: "bi bi-people", end: 15, text: "Hard Workers", delay: 300 },
  ];
function Counter() {
    useEffect(() => {
        AOS.init({
          duration: 1000,
          easing: 'ease-in-out',
          once: true,
          mirror: false,
        });
    
        new PureCounter();
      }, []);
  return (
    <div className="container">
    <div className="row" data-aos="fade-up">
      {countsData.map((count, index) => (
        <div className={`col-lg-3 col-md-6 mt-5 mt-md-0`} key={index}>
          <div className="count-box">
          <i className={count.icon}></i>
            <span 
              data-purecounter-start="0" 
              data-purecounter-end={count.end} 
              data-purecounter-duration="1" 
              className="purecounter">
            </span>
            <p>{count.text}</p>
          </div>
        </div>
      ))}
    </div>
  </div>
  )
}

export default Counter