import React, { useEffect } from 'react';
import GLightbox from 'glightbox';
import AOS from 'aos';
import 'glightbox/dist/css/glightbox.css';
import 'aos/dist/aos.css';
import './services.css';



const servicesData = [
    { icon: "/images/services/service1.png", color: "#ffbb2c", title: "Errand Requests", desc: "Need something picked up? Request an Air-Riders in seconds and get your items delivered in minutes.", delay: 50 },
    { icon: "/images/services/service2.png", color: "#5578ff", title: "Scheduled Deliveries", desc: "Plan ahead. Schedule your errands for a specific time and date.", delay: 100 },
    { icon: "/images/services/service3.png", color: "#e80368", title: "Real-time Tracking", desc: "Know exactly where your Air-Riders is with our real-time tracking feature.", delay: 150 },
    { icon: "/images/services/service4.png", color: "#e361ff", title: "Instant Notifications", desc: "Stay informed with instant notifications for every step of the errand.", delay: 200 },
    { icon: "/images/services/service5.png", color: "#47aeff", title: "Multiple Stops", desc: "Need to pick up from multiple locations? We’ve got you covered.", delay: 250 },
    { icon: "/images/services/service1.png", color: "#ffa76e", title: "Special Requests", desc: "Have specific instructions? Let us know, and we’ll make it happen.", delay: 300 },
    { icon: "/images/services/service1.png", color: "#11dbcf", title: "In-app Communication", desc: "Chat or call your Air-Riders directly through our app.", delay: 350 },
    { icon: "/images/services/service1.png", color: "#4233ff", title: "Rate and Review", desc: "Share your experience by rating and reviewing your Air-Riders.", delay: 400 },
  ];

function OurServices() {

    
    useEffect(() => {
        AOS.init({
          duration: 1000,
          easing: 'ease-in-out',
          once: true,
          mirror: false,
        });
    }, []);
  return (
    
   
    <div className="container">
        <div className="section-title" data-aos="fade-up">
          <h2>Services</h2>
          <p>What We Offer</p>
        </div>

        <div className="row" data-aos="fade-left">
          {servicesData.map((service, index) => (
            <div className={`col-lg-4 col-md-6 col-sm-12 mt-4 `} key={index}>
              <div className="icon-box" data-aos="zoom-in" data-aos-delay={service.delay}>
                <img src={service.icon} className="img-fluid" alt=""  />
                <div>
                  <h3><a href="#">{service.title}</a></h3>
                  <p>{service.desc}</p>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>


  )
}

export default OurServices