import React, { useRef, useState } from 'react'
import './Modal.scss'
import DoubleInputGroup from '../form/DoubleInputGroup';
import UploadInput from '../form/UploadInput';
import { useFileUpload } from '../form/hooks/useFileUpload';
import axios from 'axios';
import { toast } from 'react-toastify';
import { BeatLoader } from 'react-spinners';
import SectionDescription from '../form/SectionDescription';
import SelectInput from '../form/SelectInput';
import InputField from '../form/InputField';

function StoreRegisterModal({setStoreModal,storeformData,setStoreFormData}) {
    const [isLoading,setIsLoading] = useState(false)
    const cacRef = useRef();
    const addressRef = useRef();
    const handleInputChange = (e) => {
        const { name, value, type, checked } = e.target;
        setStoreFormData({
          ...storeformData,
          [name]: type === 'checkbox' ? checked : value,
        });
    };

    const {
        file: cacFile,
        fileUrl: cacFileUrl,
        uploadProgress: cacPerc,
        isFileUploading: isCacChange,
        uploadError: cacError,
        handleFileUpload: handleCacUpload
    } = useFileUpload();
    const {
        file: addressFile,
        fileUrl: addressFileUrl,
        uploadProgress: addressPerc,
        isFileUploading: isAddressChange,
        uploadError: addressError,
        handleFileUpload: handleAddressUpload
    } = useFileUpload();


    const handleSubmit = async (e)=>{
        e.preventDefault();
        setIsLoading(true)
        try{

            
            const data = {
                business_type:storeformData.business_type,
                fullname: storeformData.fullname,
                businessName: storeformData.businessName,
                businessAddress:storeformData.businessAddress,
                email: storeformData.email,
                phoneNumber: storeformData.phoneNumber,
                password: storeformData.password,
                confirmPassword:storeformData.confirmPassword,
                location:storeformData.country,
                license:storeformData.license,
                cac:cacFileUrl || '',
                isAgreement: storeformData.isAgreement,
            }
            console.log("Data: ",data)
            const url = "https://airander.onrender.com/store/register"
            // const url = "http://localhost:6001/store/register"
            const res = await axios.post(url,{...data})
            setIsLoading(false)
            console.log("result:",res.data)
            setStoreFormData({
                fullname: "",
                businessName:"",
                businessAddress:"",
                email: "",
                phoneNumber: "",
                isAgreement: "",
                password: "",
                confirmPassword:"",
                country:"",
                address_proof:"",
                cac:""
            })
            setStoreModal(false)
            toast.success("Registered successfully; Please check your email to verify your account")

        }catch(err){
            setIsLoading(false)

            const errMessage = err.response?.data.error
            toast.error(errMessage)
            console.log(err)
        }
    }
  return (
    <div className='balance_modal'  onClick={setStoreModal.bind(this, false)} >
        <div onClick={(e) => e.stopPropagation()} className='balance_modal_content'>
          <div className='balance_modal_wrapper'>
              <img 
              onClick={setStoreModal.bind(this, false)}
              className='otc_back' src="/images/backIcon.svg" alt='close' 
              />
          
            <div className="modal-title">
                <div className='step_title'>
                    <div >
                        <p className="enter-amount"> Register as a business</p>
                        <p className="enter-an-amount">Please provide the details of the bank for this payout.</p>
                    </div>
                    <img src='/images/otc/step_one.svg' alt='' />
                </div>
            </div>

            <div className='otcform_container' style={{paddingTop:"10px"}}>
            <form onSubmit={handleSubmit} >
 
                        <div style={{display:"flex",flexDirection:"column"}}>
                            <SelectInput name="business_type" handleChange={handleInputChange} value={storeformData.business_type} label="Select Your Business Type" >
                                <option value="pharmacy">Pharmacy</option>
                                <option value="stores">Stores</option>
                                <option value="others">others</option>

                            </SelectInput>
                            <DoubleInputGroup label1="Fullname" label2="Bussiness Name" handleChange={handleInputChange} name1="fullname" name2="businessName" value1={storeformData.fullname} value2={storeformData.businessName}/>
                            <DoubleInputGroup label1="Email" label2="Phone Number" handleChange={handleInputChange} name1="email" name2="phoneNumber" value1={storeformData.email} value2={storeformData.phoneNumber}/>
                            <DoubleInputGroup type1='password' type2="password" label1="Password" label2="Confirm Password" handleChange={handleInputChange} name1="password" name2="confirmPassword" value1={storeformData.password} value2={storeformData.confirmPassword} />
                            <DoubleInputGroup label1="Location" label2="Business Address" handleChange={handleInputChange} name1="location" name2="businessAddress" value1={storeformData.location} value2={storeformData.businessAddress} />
                            {
                                storeformData.business_type ==="pharmacy" &&
                                <InputField label="License Number" name="license"/>
                            }
                            <SectionDescription name="Document Upload"/>
                            <UploadInput 
                            label="CAC" 
                            label1="(optional)"
                            refs={cacRef}
                            handleChange={handleCacUpload}
                            file={cacFile}
                            error={cacError}
                            perc={cacPerc}
                            progressChange={isCacChange}
                            />

                            {/* <UploadInput 
                            label="Proof of Address" 
                            label1="(Nepa bill, BankStatment)"
                            refs={addressRef}
                            handleChange={handleAddressUpload}
                            file={addressFile}
                            error={addressError}
                            perc={addressPerc}
                            progressChange={isAddressChange}
                            /> */}
                        </div>
                         <div style={{display:"flex",alignItems:"center", gap:"10px",marginTop:"15px"}}>

                         <input
                            type="checkbox"
                            className="form-check-input"
                            id="isAgreement"
                            name="isAgreement"
                            checked={storeformData.isAgreement}
                            onChange={handleInputChange}
                        />
                        <p className='input_text' style={{margin:"0px",padding:"0px",color:"#800080"}}> Agree to our Terms of Service and Privacy policy</p>

                         
                        </div>


                        <div className="modal-btn" style={{display:"flex", gap:"12px", marginTop:"32px"}}>
                            <button onClick={setStoreModal.bind(this,false)} className="btn otc_btn_cancel">
                                Cancel
                            </button>
                        
                            <button  className="btn" disabled={isLoading} >   
                                {
                                    isLoading ?
                                    <BeatLoader /> :
                                    <>
                                    Sign Up
                                    </>
                                }
                            </button>:

                        </div>

            </form>

                
            </div>

            
          </div>
        </div>
    </div>
  )
}

export default StoreRegisterModal