import React, { useEffect } from "react";
import GLightbox from "glightbox";
import AOS from "aos";
import "glightbox/dist/css/glightbox.css";
import "aos/dist/aos.css";
import "./about.css";
import "boxicons/css/boxicons.min.css";

function About() {
  useEffect(() => {
    // Initialize GLightbox
    const lightbox = GLightbox({
      selector: ".glightbox",
    });

    // Initialize AOS (Animate On Scroll)
    AOS.init({
      duration: 1000,
      easing: "ease-in-out",
      once: true,
      mirror: false,
    });

    // Cleanup on component unmount
    return () => {
      lightbox.destroy();
    };
  }, []);
  return (
    <div className="container-fluid">
      <div className="row">
        <div
          className="col-xl-5 col-lg-6 video-box d-flex justify-content-center align-items-stretch"
          data-aos="fade-right"
        >
          {/* <a href="https://www.youtube.com/watch?v=StpBR2W8G5A" className="glightbox play-btn mb-4"></a> */}
        </div>

        <div
          className="col-xl-7 col-lg-6 icon-boxes d-flex flex-column align-items-stretch justify-content-center py-5 px-lg-5"
          data-aos="fade-left"
        >
          <h3>Welcome to AiRander</h3>
          <p>
            Your eco-friendly, affordable, and convenient errand service at your
            fingertips!
          </p>
          <p>
            At AiRander, we strive to simplify your daily errands by connecting
            you with Air-Riders—dedicated individuals with bicycles ready to
            help you with your short-distance needs. Whether you need groceries,
            items from a nearby store, or a quick delivery, AiRander is here to
            make it happen within a 1km radius.
          </p>
          <h3>Why Choose AiRander?</h3>

          <div className="icon-box" data-aos="zoom-in" data-aos-delay="100">
            <div className="icon">
              <i className="bx bx-leaf"></i>
            </div>
            <h4 className="title">
              <a href="/">Eco-friendly Transportation</a>
            </h4>
            <p className="description">
              Our Air-Riders use bicycles, promoting an eco-friendly mode of
              transportation that reduces carbon emissions
            </p>
          </div>

          <div className="icon-box" data-aos="zoom-in" data-aos-delay="200">
            <div className="icon">
              <i className="bx bx-wallet"></i>
            </div>
            <h4 className="title">
              <a href="/">Affordable Solutions</a>
            </h4>
            <p className="description">
              Enjoy cost-effective errand services without breaking the bank.
            </p>
          </div>

          <div className="icon-box" data-aos="zoom-in" data-aos-delay="300">
            <div className="icon">
              <i className="bx bx-map"></i>
            </div>
            <h4 className="title">
              <a href="/">Local Convenience</a>
            </h4>
            <p className="description">
              Designed for short-distance errands, AiRander focuses on serving
              your immediate neighborhood.
            </p>
          </div>

          <div className="icon-box" data-aos="zoom-in" data-aos-delay="400">
            <div className="icon">
              <i className="bx bx-time"></i>
            </div>
            <h4 className="title">
              <a href="/">Time-Saving</a>
            </h4>
            <p className="description">
              Save time and effort by letting our Air-Riders handle your
              errands, so you can focus on what matters most.
            </p>
          </div>

          <div className="icon-box" data-aos="zoom-in" data-aos-delay="500">
            <div className="icon">
              <i className="bx bx-shield"></i>
            </div>
            <h4 className="title">
              <a href="/">Reliable and Safe</a>
            </h4>
            <p className="description">
              Our platform ensures reliable and safe deliveries with Air-Riders
              who are vetted and trained.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default About;
