import React, { useEffect } from 'react';
import Swiper from 'swiper';
import 'swiper/swiper-bundle.css';
import AOS from 'aos';
import 'aos/dist/aos.css';
import './testimonial.css';


const testimonialsData = [
    {
      img: 'images/testimonials/testimonials-1.jpg',
      name: 'Saul Goodman',
      role: 'CEO & Founder',
      quote:
        'Proin iaculis purus consequat sem cure digni ssim donec porttitora entum suscipit rhoncus. Accusantium quam, ultricies eget id, aliquam eget nibh et. Maecen aliquam, risus at semper.',
    },
    {
      img: 'images/testimonials/testimonials-2.jpg',
      name: 'Sara Wilsson',
      role: 'Designer',
      quote:
        'Export tempor illum tamen malis malis eram quae irure esse labore quem cillum quid cillum eram malis quorum velit fore eram velit sunt aliqua noster fugiat irure amet legam anim culpa.',
    },
    {
      img: 'images/testimonials/testimonials-3.jpg',
      name: 'Jena Karlis',
      role: 'Store Owner',
      quote:
        'Enim nisi quem export duis labore cillum quae magna enim sint quorum nulla quem veniam duis minim tempor labore quem eram duis noster aute amet eram fore quis sint minim.',
    },
    {
      img: 'images/testimonials/testimonials-4.jpg',
      name: 'Matt Brandon',
      role: 'Freelancer',
      quote:
        'Fugiat enim eram quae cillum dolore dolor amet nulla culpa multos export minim fugiat minim velit minim dolor enim duis veniam ipsum anim magna sunt elit fore quem dolore labore illum veniam.',
    },
    {
      img: 'images/testimonials/testimonials-5.jpg',
      name: 'John Larson',
      role: 'Entrepreneur',
      quote:
        'Quis quorum aliqua sint quem legam fore sunt eram irure aliqua veniam tempor noster veniam enim culpa labore duis sunt culpa nulla illum cillum fugiat legam esse veniam culpa fore nisi cillum quid.',
    },
  ];
function Testimonial({setIsModalOpen,setIsGetApp}) {
    useEffect(() => {
        AOS.init({
          duration: 1000,
          easing: 'ease-in-out',
          once: true,
          mirror: false,
        });
    
        new Swiper('.testimonials-slider', {
          slidesPerView: 1,
          spaceBetween: 30,
          pagination: {
            el: '.swiper-pagination',
            clickable: true,
          },
          breakpoints: {
            768: {
              slidesPerView: 2,
            },
            1024: {
              slidesPerView: 3,
            },
          },
        });
      }, []);
  return (
    <div className="container">
    <div className="testimonials-slider swiper" data-aos="fade-up" data-aos-delay="100">

      <div className="swiper-wrapper">
            <div className="row content">
              <div className="col-md-4 order-1 order-md-2" data-aos="fade-left">
                <img src="/images/ride1.jpeg" className="img-fluid" alt="Details" />
              </div>
              <div className="col-md-8 pt-5 order-2 order-md-1" data-aos="fade-up">
               
                  <div className="testimonial-item">
                    
                    <h3>Request in seconds, ride in minutes.</h3>
                    <h4>Available for iOS and Android devices.</h4>
                    <div className="text-center text-lg-start btn_testimonial" onClick={setIsGetApp.bind(this,true)} style={{width:"200px",cursor:"pointer"}}>
                      <p  className="btn-get-started " style={{cursor:"pointer"}}>
                        Get the app
                      </p>
                    </div>
                  </div>
              </div>
            </div>
              
         
      </div>
      {/* <div className="swiper-pagination"></div> */}
    </div>
  </div>
  )
}

export default Testimonial