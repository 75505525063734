import React from 'react'

function DownLoadModal({setIsGetApp}) {
    const handleChange = (e)=>{
        // setAmountInput(e.target.value);
      }
     
      const handleClick = ()=>{
        // iniateCharge();
      }
      return (
        <div className="overlay" onClick={setIsGetApp.bind(this,false)}>
        <div className="enter-amount-modal">
          <div className="modal-wrapper">
    
            <div>
            <img style={{cursor:"pointer"}} onClick={setIsGetApp.bind(this,false)} src={process.env.PUBLIC_URL + "/images/backArrowBalanceModal.svg"} alt="icon" />
            </div>
            
            <div className="modal-title">
              {/* <p className="enter-amount">Enter amount</p> */}
              
            </div>
    
          <div className="input-amount">
              <p className="enter-an-amount amount-input">Coming Soon</p>
            
          </div>
    
            <div className="modal-btn">
              <button onClick={setIsGetApp.bind(this,false)} className="sec-btn">
                Cancel
              </button>
              {/* {
                isAmountLoading ?
                <button  className="btn"> 
                    <BeatLoader
                        color="#FFF"
                        cssOverride={{}}
                    />                
                </button>:
              } */}
              <button onClick={handleClick} className="btn">
                Confirm
              </button>
            </div>
            </div>
        </div>
        </div>
      );
}

export default DownLoadModal