import React, { useEffect } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';
import './faqs.css'; 

function Faqs() {
    useEffect(() => {
        AOS.init({
          duration: 1000,
          easing: 'ease-in-out',
          once: true,
          mirror: false,
        });
      }, []);
  return (
    <div className="container">
        <div className="section-title" data-aos="fade-up">
          <h2>F.A.Q</h2>
          <p>Frequently Asked Questions</p>
        </div>

        <div className="faq-list">
          <ul>
            <li data-aos="fade-up">
              <i className="bx bx-help-circle icon-help"></i>{' '}
              <a
                data-bs-toggle="collapse"
                className="collapsed"
                href="#faq-list-1"
                role="button"
                aria-expanded="false"
                aria-controls="faq-list-1"
              >
               What areas does AiRander serve?{' '}
                <i className="bx bx-chevron-down icon-show"></i>
                <i className="bx bx-chevron-up icon-close"></i>
              </a>
              <div id="faq-list-1" className="collapse" data-bs-parent=".faq-list">
                <p>
                AiRander currently serves the following areas: Lekki, VI, Ikotun, Ikeja, Ikorodu, and Ajah. We plan to expand our service area in the future to accommodate more users.
                </p>
              </div>
            </li>

            <li data-aos="fade-up" data-aos-delay="100">
              <i className="bx bx-help-circle icon-help"></i>{' '}
              <a
                data-bs-toggle="collapse"
                className="collapsed"
                href="#faq-list-2"
                role="button"
                aria-expanded="false"
                aria-controls="faq-list-2"
              >
               What types of items can I request for delivery?{' '}
                <i className="bx bx-chevron-down icon-show"></i>
                <i className="bx bx-chevron-up icon-close"></i>
              </a>
              <div id="faq-list-2" className="collapse" data-bs-parent=".faq-list">
                <p>
                You can request a wide range of items including groceries, documents, small packages, and other essentials. If you have special requests, you can specify them when placing your errand request.
                </p>
              </div>
            </li>

            <li data-aos="fade-up" data-aos-delay="200">
              <i className="bx bx-help-circle icon-help"></i>{' '}
              <a
                data-bs-toggle="collapse"
                className="collapsed"
                href="#faq-list-3"
                role="button"
                aria-expanded="false"
                aria-controls="faq-list-3"
              >
                How do I track my errand?{' '}
                <i className="bx bx-chevron-down icon-show"></i>
                <i className="bx bx-chevron-up icon-close"></i>
              </a>
              <div id="faq-list-3" className="collapse" data-bs-parent=".faq-list">
                <p>
                You can track your Air-Riders in real-time through the AiRander app. You will receive instant notifications and updates about the status of your delivery.
                </p>
              </div>
            </li>

            <li data-aos="fade-up" data-aos-delay="300">
              <i className="bx bx-help-circle icon-help"></i>{' '}
              <a
                data-bs-toggle="collapse"
                className="collapsed"
                href="#faq-list-4"
                role="button"
                aria-expanded="false"
                aria-controls="faq-list-4"
              >
               Can I request multiple stops for one errand?{' '}
                <i className="bx bx-chevron-down icon-show"></i>
                <i className="bx bx-chevron-up icon-close"></i>
              </a>
              <div id="faq-list-4" className="collapse" data-bs-parent=".faq-list">
                <p>
                Yes, you can request multiple stops within the same errand. Simply specify the stops when placing your request.
                </p>
              </div>
            </li>

            <li data-aos="fade-up" data-aos-delay="400">
              <i className="bx bx-help-circle icon-help"></i>{' '}
              <a
                data-bs-toggle="collapse"
                className="collapsed"
                href="#faq-list-5"
                role="button"
                aria-expanded="false"
                aria-controls="faq-list-5"
              >
                How do I communicate with my Air-Riders?{' '}
                <i className="bx bx-chevron-down icon-show"></i>
                <i className="bx bx-chevron-up icon-close"></i>
              </a>
              <div id="faq-list-5" className="collapse" data-bs-parent=".faq-list">
                <p>
                You can communicate with your Air-Riders directly through the AiRander app using the in-app messaging feature.
                </p>
              </div>
            </li>
          </ul>
        </div>
    </div>
  )
}

export default Faqs