import React from 'react';
import Progress from '../Progress';
import './form.scss'

function UploadInput({ label, label1, refs, handleChange, file, perc, progressChange, error }) {
  const onFileChange = (e) => {
    handleChange(e.target.files); // Ensure that only the files array is passed
  };

   

  return (
    <div className="mb-4 flex flex-col">
      <label className="input_text" htmlFor="file">
        {label} <span>{label1}</span>
      </label>

      <div className="upload_file flex w-full" style={{display:"flex",width:"100%"}}> 
        <input 
          type="file"
          onChange={onFileChange} // Use the new handler
          name="image"
          hidden
          ref={refs}
        />
        {
          !file ? (
            <div className="upload_init flex items-center justify-center flex-col w-full">
              <img
                onClick={() => refs.current.click()}
                src="/images/upload.svg"
                alt="upload"
                style={{ cursor: "pointer" }}
              />
              <p className="upload_text">
                <span className="text-[#1C716D]">Click to upload </span>
                (SVG, PNG, JPG or GIF (max. 800x400px))
              </p>
            </div>
          ) : (
            <div className="flex gap-2 items-center p-[10px]">
              <img
                onClick={() => refs.current.click()}
                src="/images/file.svg"
                alt="file"
                style={{ cursor: "pointer" }}
              />
              <p className="form_lbl" style={{ margin: "0" }}>{file?.name}</p>
            </div>
          )
        }
      </div>

      {progressChange && <Progress percentage={perc} />}

      {error && <p className="text-red-500 text-xs italic">Unable to Upload</p>}
    </div>
  );
}

export default UploadInput;
