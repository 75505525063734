import { useEffect, useRef, useState } from 'react';
import logo from './logo.svg';
import './App.css';
import 'react-toastify/dist/ReactToastify.css';


import { Swiper, SwiperSlide } from 'swiper/react';
import Header from './components/header/Header';
import Hero from './components/hero/Hero';

import GLightbox from 'glightbox';
import AOS from 'aos';
import 'glightbox/dist/css/glightbox.css';
import 'aos/dist/aos.css';
import About from './components/about/About';
import OurServices from './components/services/OurServices';
import Counter from './components/counter/Counter';
import Gallery from './components/gallery/Gallery';
import Testimonial from './components/testimonial/Testimonial';
import Faqs from './components/faqs/Faqs';
import Contact from './components/contact/Contact';
import Footer from './components/footer/Footer';
import Team from './components/team/Team';
import CommingSoon from './components/common/modal/CommingSoon';
import RegisterRiderModal from './components/modals/CommingSoon';
import StoreRegisterModal from './components/modals/StoreRegisterModal';
import RiderRegisterModal from './components/modals/RiderRegisterModal';
import DownLoadModal from './components/modals/DownLoadModal';
import { ToastContainer } from 'react-toastify';
import { useFileUpload } from './components/form/hooks/useFileUpload';
import IndividualModal from './components/modals/IndividualModal';


function App() {
  const [isModalOpen,setIsModalOpen] = useState(false)
  const [isRiderModal,setIsRiderModal] = useState(false)
  const [isStoreModal,setStoreModal] = useState(false)
  const [isIndividualModal,setIsIndividualModal] = useState(false)

  const [isGetApp,setIsGetApp] = useState(false)

  const [storeformData, setStoreFormData] = useState({
    business_type:"pharmacy",
    fullname: "",
    businessName: "",
    businessAddress:"",
    email: "",
    phoneNumber: "",
    location:"",
    password: "",
    confirmPassword: "",
    license:"",
    description:"",
    isAgreement: false,
    
  });
  const [individualformData, SetIndividualFormData] = useState({
    fullname: "",
    email: "",
    phoneNumber:"",
    address: "",
    password: "",
    confirmPassword: "",
    isAgreement: false,
   
  });
  const statementRef = useRef();
  const [riderFormData, setRiderFormData] = useState({
    fullname: "",
    email: "",
    phoneNumber: "",
    password: "",
    confirmPassword:"",
    country:"",
    address:"",
    guarantor_name: "",
    guarantor_phone:"",
    guarantor_occupation:"",
    guarantor_relationship:"",
    isAgreement: false,
  });

  const {
    file: statementFile,
    fileUrl: statementFileUrl,
    uploadProgress: statementPerc,
    isFileUploading: isStatementChange,
    uploadError: statementError,
    handleFileUpload: handleStatementUpload
  } = useFileUpload();


  useEffect(() => {
    if(isStoreModal) {
      document.body.style.overflow = 'hidden';
    } else{
      document.body.style.overflow = 'unset';

    }
 }, [isStoreModal ]);


  useEffect(() => {
    // Initialize GLightbox
    const lightbox = GLightbox({
      selector: '.glightbox',
    });

    // Initialize AOS (Animate On Scroll)
    AOS.init({
      duration: 1000,
      easing: 'ease-in-out',
      once: true,
      mirror: false,
    });

    // Cleanup on component unmount
    return () => {
      lightbox.destroy();
    };
  }, []);
  

  return (
    <div className='page'>
    
    <Header setIsRiderModal={setIsRiderModal} setStoreModal={setStoreModal} setIsIndividualModal={setIsIndividualModal}/>
    <Hero setIsGetApp={setIsGetApp}  />
   
    
    <main id="main">
    <section id="about" className="about">
      <About />
    </section>
    <section id="features" className="features">
      <OurServices />
    </section>

    <section id="counts" class="counts"> 
    <Counter/>
    </section>
    <section id="gallery" class="gallery">
      <Gallery/>
    </section>
    <section id="testimonials" class="testimonials">
      <Testimonial  setIsGetApp={setIsGetApp} setIsModalOpen={setIsModalOpen}/>
    </section>

    <section id="faq" class="faq section-bg">
      <Faqs  />
    </section>
    <section id="contact" class="contact">
      <Contact/>
    </section>

    <Footer />

    </main>

    {
      isRiderModal &&
      <RiderRegisterModal riderFormData={riderFormData} setRiderFormData={setRiderFormData} setIsRiderModal={setIsRiderModal} />
    }
    {
      isStoreModal &&
      <StoreRegisterModal setStoreFormData={setStoreFormData} storeformData={storeformData} setStoreModal={setStoreModal} />
    }
    {
      isIndividualModal &&
      <IndividualModal SetIndividualFormData={SetIndividualFormData} setIsIndividualModal={setIsIndividualModal} individualformData={individualformData}/>
    }
    {
      isGetApp &&
      <DownLoadModal  setIsGetApp={setIsGetApp} />
    }
 
    <ToastContainer />
    </div>
    
  );
}

export default App;
