import React, { useRef, useState } from 'react'
import './Modal.scss'
import DoubleInputGroup from '../form/DoubleInputGroup';

import axios from 'axios';
import { toast } from 'react-toastify';
import { BeatLoader } from 'react-spinners';


function IndividualModal({individualformData,SetIndividualFormData,setIsIndividualModal}) {
    const [isLoading,setIsLoading] = useState(false)
   
    const handleInputChange = (e) => {
        const { name, value, type, checked } = e.target;
        SetIndividualFormData({
          ...individualformData,
          [name]: type === 'checkbox' ? checked : value,
        });
    };


    const handleSubmit = async (e)=>{
        e.preventDefault();
        setIsLoading(true)
        try{

            
            const data = {
                fullname: individualformData.fullname,
                email: individualformData.email,
                phoneNumber: individualformData.phoneNumber,
                address:individualformData.address,
                password: individualformData.password,
                confirmPassword:individualformData.confirmPassword,
                isAgreement: individualformData.isAgreement,
               
            }
            console.log("Data: ",data)
            const url = "https://airander.onrender.com/individual/register"
            // const url = "http://localhost:6001/individual/register"
            const res = await axios.post(url,{...data})
            setIsLoading(false)
            console.log("result:",res.data)
            SetIndividualFormData({
                fullname: "",
                businessName: "",
                businessAddress:"",
                email: "",
                phoneNumber: "",
                isAgreement: false,
                password: "",
                confirmPassword: "",
                country:""
            })
            setIsIndividualModal(false)
            toast.success("Registered Successfully: Please check your email to verify your account")

        }catch(err){
            setIsLoading(false)
            const errMessage = err.response?.data.error
            toast.error(errMessage)
            console.log(err)
        }
    }
  return (
    <div className='balance_modal'  onClick={setIsIndividualModal.bind(this, false)} >
        <div onClick={(e) => e.stopPropagation()} className='balance_modal_content'>
          <div className='balance_modal_wrapper'>
              <img 
              onClick={setIsIndividualModal.bind(this, false)}
              className='otc_back' src="/images/backIcon.svg" alt='close' 
              />
          
            <div className="modal-title">
                <div className='step_title'>
                    <div >
                        <p className="enter-amount"> Register as an Individual</p>
                        <p className="enter-an-amount">Please provide the details of the bank for this payout.</p>
                    </div>
                    <img src='/images/otc/step_one.svg' alt='' />
                </div>
            </div>

            <div className='otcform_container' style={{paddingTop:"10px"}}>
            <form onSubmit={handleSubmit} >
 
                        <div style={{display:"flex",flexDirection:"column"}}>
                            <DoubleInputGroup label1="Fullname" label2="Email" handleChange={handleInputChange} name1="fullname" name2="email" value1={individualformData.fullname} value2={individualformData.email}/>
                            <DoubleInputGroup label1="Phone Number" label2="Address" handleChange={handleInputChange} name1="phoneNumber"  name2="address" value1={individualformData.phoneNumber} value2={individualformData.address}/>
                            <DoubleInputGroup type1='password' type2='password' label1="Password" label2="Confirm Password" handleChange={handleInputChange} name1="password" name2="confirmPassword" value1={individualformData.password} value2={individualformData.confirmPassword} />
                        </div>
                         <div style={{display:"flex",alignItems:"center", gap:"10px",marginTop:"15px"}}>

                         <input
                            type="checkbox"
                            className="form-check-input"
                            id="isAgreement"
                            name="isAgreement"
                            checked={individualformData.isAgreement}
                            onChange={handleInputChange}
                        />
                        <p className='input_text' style={{margin:"0px",padding:"0px",color:"#800080"}}> Agree to our Terms of Service and Privacy policy</p>

                         
                        </div>


                        <div className="modal-btn" style={{display:"flex", gap:"12px", marginTop:"32px"}}>
                            <button onClick={setIsIndividualModal.bind(this,false)} className="btn otc_btn_cancel">
                                Cancel
                            </button>
                        
                            <button  className="btn" disabled={isLoading} >   
                                {
                                    isLoading ?
                                    <BeatLoader /> :
                                    <>
                                    Sign Up
                                    </>
                                }
                            </button>:

                        </div>

            </form>

                
            </div>

            
          </div>
        </div>
    </div>
  )
}

export default IndividualModal